<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import router from "./router";

export default {
  name: "App",
  created() {
    this.isMobile();
  },
  methods: {
    isMobile() {
      const redirectPath = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
        ? "m_index"
        : "index";
      let path = window.location.pathname;
      let url = path.slice(0, 3);
      //取出存在路由的path
      const routeArr = [];

      this.$router.options.routes.forEach((item) => {
        return routeArr.push(item.path);
      });
      //PC端路由判断
      if (redirectPath == "index") {
        if (url == "/") {
          this.$router.push("/index");
        }
        if (url == "/m_") {
          path = path.replace("m_", "");
          if (routeArr.indexOf(path) != -1) {
            this.$router.push(path);
          } else {
            this.$router.push("/index");
          }
        } else {
          // console.log('/'+path.slice(1,).split('/')[0],'path')
          let currentPath = "/" + path.slice(1).split("/")[0] + "/:id";
          if (
            routeArr.indexOf(path) != -1 ||
            routeArr.indexOf(currentPath) != -1
          ) {
            this.$router.push(path);
          } else {
            this.$router.push("/index");
          }
        }
      } else {
        if (url == "/") {
          this.$router.push("/m_index");
        }
        if (url == "/m_") {
          let currentPath = "/" + path.slice(1).split("/")[0] + "/:id";
          if (
            routeArr.indexOf(path) != -1 ||
            routeArr.indexOf(currentPath) != -1
          ) {
            this.$router.push(path);
          } else {
            this.$router.push("/index");
          }
        } else {
          path = path.replace("/", "/m_");
          if (routeArr.indexOf(path) != -1) {
            this.$router.push(path);
          } else {
            this.$router.push("/m_index");
          }
        }
      }
    },
  },
  mounted() {
    this.$http({
      url: "/anli/recVisitor",
    }).then((res) => {
      console.log(res, "访问");
    });
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}
</style>
