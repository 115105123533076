import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入Element - ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入网络请求
import { request } from './network/request'
Vue.prototype.$http = request

Vue.config.productionTip = false

Vue.use(ElementUI);
//无缝滚动插件引入

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
    // 事件总线引入
Vue.prototype.$bus = new Vue()

Vue.prototype.BaseUrl = 'http://api.zbzyuan.com/'
    // Vue.prototype.BaseUrl = ''
console.log(Vue.prototype);
router.beforeEach((to, from, next) => {
    document.title = '资本中原网'
    next()
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')