import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// PC
// 首页
const Index = () =>
    import ('../views/pc/Index/Index.vue')
    // 资本热点
const Hot = () =>
    import ('../views/pc/Hot/Hot.vue')
    // 资本热点详情
const HotDetails = () =>
    import ('../views/pc/Hot/HotDetails/HotDetails.vue')
    // 资本人物
const Figure = () =>
    import ('../views/pc/Figure/Figure.vue')
    // 资本人物详情
const FigureDetails = () =>
    import ('../views/pc/Figure/FigureDetails/FigureDetails.vue')
    // 资本动态
const Dynamic = () =>
    import ('../views/pc/Dynamic/Dynamic.vue')
    // 资本动态详情
const DynamicDetails = () =>
    import ('../views/pc/Dynamic/DynamicDetails/DynamicDetails.vue')
    // 资本影视
const Video = () =>
    import ('../views/pc/Video/Video.vue')
    // 影视详情
const VideoDetails = () =>
    import ('../views/pc/Video/VideoCom/VideoDetails.vue')
    // 乡村振兴
const Rural = () =>
    import ('../views/pc/Rural/Rural.vue')
    // 乡村振兴详情
const RuralDetails = () =>
    import ('../views/pc/Rural/RuralCom/RuralDetails.vue')
    // 健康生活
const HealthyLife = () =>
    import ('../views/pc/HealthyLife/HealthyLife.vue')
    // 健康生活详情
const HealthyLifeDetails = () =>
    import ('../views/pc/HealthyLife/HealthyLifeDetails/HealthyLifeDetails.vue')
    // 投诉维权
const Complaints = () =>
    import ('../views/pc/Complaints/Complaints .vue')
    // 搜索
const Search = () =>
    import ('../views/pc/Search/Search.vue')
    //关于我们
const About = () =>
    import ('../views/pc/About/About.vue')

const DayDetails = () =>
    import ('../views/pc/DayDetails/DayDetails.vue')
    // 移动
    // 首页
const M_Index = () =>
    import ('../views/mobile/Index/Index.vue')
const M_IndexDetail = () =>
    import ('../views/mobile/Index/IndexDetail/IndexDetail.vue')
const M_IndexFigureDetail = () =>
    import ('../views/mobile/Index/IndexDetail/Figure.vue')
const M_IndexDyDetail = () =>
    import ('../views/mobile/Index/IndexDetail/Dynamic.vue')
    // 资本热点
const M_Hot = () =>
    import ('../views/mobile/Hot/Hot.vue')
    // 资本详情
const M_HotDetail = () =>
    import ('../views/mobile/Hot/HotDetails/HotDetails.vue')
    // 资本人物
const M_Figure = () =>
    import ('../views/mobile/Figure/Figure.vue')
    // 资本人物详情
const M_FigureDetail = () =>
    import ('../views/mobile/Figure/FigureDetails/FigureDetails.vue')
    // 资本动态
const M_Dynamic = () =>
    import ('../views/mobile/Dynamic/Dynamic.vue')
    // 资本动态详情
const M_DynamicDetail = () =>
    import ('../views/mobile/Dynamic/DynamicDetails/DynamicDetails.vue')

//乡村振兴
const M_Rural = () =>
    import ('../views/mobile/Rural/Rural.vue')
    //乡村振兴详情
const M_RuralDetail = () =>
    import ('../views/mobile/Rural/RuralDetail/RuralDetail')
    //健康生活
const M_HealthyLife = () =>
    import ('../views/mobile/HealthyLife/HealthyLife.vue')
    //健康生活详情
const M_HealthyLifeDetail = () =>
    import ('../views/mobile/HealthyLife/HealthyLifeDetails/HealthyLifeDetails.vue')
    //投诉维权
const M_Complaints = () =>
    import ('../views/mobile/Complaints/Complaints.vue')
    // 搜索页面
const M_Search = () =>
    import ('../views/mobile/Search/Search.vue')
const M_SearchDetail = () =>
    import ('../views/mobile/Figure/FigureDetails/FigureDetails.vue')
    // 公司简介
const M_About = () =>
    import ('../views/mobile/About/About.vue')
    // 内容详情
const M_Details = () =>
    import ('../views/mobile/Details/Details.vue')
    // 影视影像
const M_Video = () =>
    import ('../views/mobile/Video/Video.vue')
    // 影视详情
const M_VideoDetails = () =>
    import ('../views/mobile/Video/VideoCom/VideoDetails.vue')


const routes = [
    // PC
    {
        // 默认首页
        path: '/',
        redirect: 'index',
    }, {
        // 首页
        path: '/index',
        name: 'Index',
        component: Index
    }, {
        // 资本热点
        path: '/hot',
        component: Hot
    }, {
        path: '/hotdetails/:id',
        component: HotDetails
    }, {
        // 资本人物
        path: '/figure',
        component: Figure
    }, {
        // 资本人物详情  
        path: '/figuredetails/:id',
        component: FigureDetails
    }, {
        // 资本动态
        path: '/dynamic',
        component: Dynamic
    }, {
        // 资本动态详情
        path: '/dynamicdetails/:id',
        component: DynamicDetails
    }, {
        // 资本影视
        path: '/video',
        component: Video
    }, {
        // 影视详情
        path: '/videodetails/:id',
        component: VideoDetails
    }, {
        // 乡村振兴
        path: '/rural',
        component: Rural
    }, {
        // 乡村振兴详情
        path: '/ruraldetails/:id',
        component: RuralDetails
    }, {
        // 健康生活
        path: '/healthylife',
        component: HealthyLife
    }, {
        //   健康生活详情  
        path: '/healthylifedetails/:id',
        component: HealthyLifeDetails
    }, {
        //投诉维权
        path: '/complaints',
        component: Complaints
    }, {
        //搜索页面
        path: '/search',
        component: Search
    }, {
        // 关于我们
        path: '/about',
        component: About
    }, {
        // 今日热点详情
        path: '/daydetails/:id',
        component: DayDetails
    },

    // 移动
    {
        // 首页
        path: '/m_index',
        name: 'M_Index',
        component: M_Index
    },
    {
        // 首页热点
        path: '/m_indexdetail/:id',
        component: M_IndexDetail
    }, {
        // 首页热点
        path: '/m_indexfiguredetail/:id',
        component: M_IndexFigureDetail
    },
    {
        // 首页热点
        path: '/m_indexdydetail/:id',
        component: M_IndexDyDetail
    }, {
        //资本热点
        path: '/m_hot',
        component: M_Hot
    }, {
        //资本热点详情
        path: '/m_hotdetails/:id',
        component: M_HotDetail
    }, {
        // 资本人物
        path: '/m_figure',
        component: M_Figure
    },
    {
        path: '/m_figuredetail/:id',
        component: M_FigureDetail
    }, {
        // 资本动态
        path: '/m_dynamic',
        component: M_Dynamic
    },
    {
        path: '/m_dynamicdetail/:id',
        component: M_DynamicDetail
    }, {
        //乡村振兴 
        path: '/m_rural',
        component: M_Rural
    },
    {
        //乡村振兴详情
        path: '/m_ruraldetail/:id',
        component: M_RuralDetail
    }, {
        //健康生活
        path: '/m_healthylife',
        component: M_HealthyLife
    },
    {
        //健康生活详情
        path: '/m_healthylifedetail/:id',
        component: M_HealthyLifeDetail

    }, {
        // 投诉维权
        path: '/m_complaints',
        component: M_Complaints
    }, {
        // 搜索页
        path: '/m_search',
        component: M_Search
    },
    {
        // 搜索页
        path: '/m_searchdetail/:id',
        component: M_SearchDetail
    },

    {
        // 公司简介
        path: '/m_about',
        component: M_About
    }, {
        // 内容详情
        path: '/m_details/:id',
        component: M_Details
    }, {
        // 影视影像
        path: '/m_video',
        component: M_Video

    }, {
        //影视详情
        path: '/m_videodetails/:id',
        component: M_VideoDetails
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: () => ({
        y: 0
    }),
})

export default router